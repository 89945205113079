<template>
  <div class="holder text-center mb-2 mb-md-0" @mouseover="isHover = true" @mouseleave="isHover = false">
    <div class="gold-text medium holder_font">{{placeholder}}</div>
    <div class="dropdown_icon gold-text">
      <ios-arrow-down-icon />
    </div>
    <!--DESKTOP-->
    <transition name="slideBottom">
      <div class="select_dropdown" v-if="isHover" @mouseover="isHover = true" @click.self="isHover = false">
        <div class="dropdown_inner">
          <div class="row mx-0 my-4 justify-content-center d-md-none">
            <div class="col-11 text-center title">
              {{title}}
            </div>
            <div class="close cursor" @click="isHover = false"><ios-close-icon class="inline-icon"/></div>
          </div>
          <div class="row mx-0 mt-2 justify-content-center scroll_inner text-start">
            <FilterSelectItem v-for="(option, key) in options" :key="key" :option="option" @itemClicked="selectOption" :isSelected="selectedOptions && selectedOptions.length > 0 && this.selectedOptions.includes(option[0])" :selectedOptions="selectedOptions" />
          </div>
          <div class="row mx-0 my-3 justify-content-center">
            <div class="col-10">
              <Button color="green" width="100" btnText="Apply Filter" icon="arrow" @buttonClicked="applyFilter">
                <ios-checkmark-icon class="inline-icon" />
              </Button>
            </div>
            <div class="col-10 mt-2" v-if="isFiltered">
              <Button color="red" width="100" btnText="Clear Filter" icon="arrow" @buttonClicked="clearFilter">
                <ios-remove-icon class="inline-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--END DESKTOP-->
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FilterSelectItem: defineAsyncComponent(() => import('./FilterSelectItem.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue')),
    'ios-remove-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-remove.vue')),
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue'))
  },
  name: 'FilterSelectAppeal',
  props: ['modelValue', 'placeholder', 'options', 'title'],
  emits: ['update:modelValue', 'applyfilter', 'clearfilter'],
  data () {
    return {
      isHover: false,
      selectedOptions: []
    }
  },
  async mounted () {
    this.pageId = await parseInt(this.$route.params.id)
    if (this.pageId === 1) {
      this.selectedOptions.push('section18ATAX')
      this.applyFilter()
    }
  },
  computed: {
    isFiltered () {
      if (this.modelValue && this.modelValue.length > 0) {
        return true
      }
      return false
    },
    value: {
      get () {
        this.copyArray()
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    copyArray () {
      this.selectedOptions = [...this.modelValue]
    },
    applyFilter () {
      this.value = [...this.selectedOptions]
      this.isHover = false
    },
    clearFilter () {
      this.selectedOptions = []
      this.applyFilter()
    },
    selectOption (val) {
      console.log('selectOption', val)
      if (this.selectedOptions && this.selectedOptions.length > 0) {
        const index = this.selectedOptions.indexOf(val)
        if (index > -1) {
          this.selectedOptions.splice(index, 1)
        } else {
          this.selectedOptions.push(val)
        }
      } else {
        this.selectedOptions.push(val)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .holder {
    background-color: var(--green-color-dark);
    padding: 12px 24px;
    line-height: 1;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
  }
  .holder_font {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 13px;
    right: 16px;
    font-size: 1rem;
    z-index: 1;
  }
  .select_dropdown {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 60px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    /* background-color: pink; */
  }
  .title {
    color: var(--green-color-dark);
    font-family: "quicksand_medium";
    font-weight: 600;
    font-size: 19px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: inherit;
  }
  .dropdown_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 75vh;
    background-color: rgb(249, 250, 251);
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(51, 51, 51);
  }
  .scroll_inner {
    max-height: 350px;
    overflow-y: auto;
  }
  .form-control {
    background-color: #fff;
    color: var(--green-color);
    border-color: var(--green-color);
    border-radius: 2rem;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 500;
    text-align: left;
  }

  .slideBottom-enter-active {
    transition: all 0.5s ease;
  }
  .slideBottom-leave-active {
    transition: all 0.5s ease;
  }
  .slideBottom-enter-from {
    transform: translateY(100%);
    opacity: 1;
  }
  .slideBottom-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
  @media (min-width: 992px) {
    .select_dropdown {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100%;
      min-height: 60px;
      height: inherit;
      background-color: transparent;
      /* background-color: pink; */
    }
    .dropdown_inner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: inherit;
      max-height: inherit;
      top: 50px;
      width: 150%;
      min-width: 250px;
      background-color: rgb(249, 250, 251);
      border-radius: 20px;
      color: rgb(51, 51, 51);
    }

    .holder_font {
      font-size: 15px;
    }
    .holder {
      background-color: var(--green-color-dark);
      height: 40px;
      padding: 12px 48px 12px 32px;
      line-height: 1;
      border-radius: 100px;
      position: relative;
      cursor: pointer;
    }
    .slideBottom-enter-active {
      transition: all 0.5s ease;
    }
    .slideBottom-leave-active {
      transition: all 0.1s ease;
    }
    .slideBottom-enter-from {
      transform: translateY(0);
      opacity: 0;
    }
    .slideBottom-leave-to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
